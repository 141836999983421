import '../src/components/Osobni_stranka/App.css';

import Main from './Main';

function App() {
  return (
    <div className="App">
      <Main></Main>
    </div>
  );
}

export default App;
