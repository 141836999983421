import './App.css';

import Mapa from "./components/OpenLayers_mapa"

function App() {
  return (
    <Mapa></Mapa>
  );
}

export default App;
