// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  height: 100%;
}

button{
  position: relative;
  width: 120px;
  background-color:#f2efe9;
  border-color: #f2efe9;
  border-radius: 4px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.input_my{
  position: relative;
  width: 150px;
}

#mereni_delky_comp{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 170px;
  padding: 10px;
  justify-content: center;
}

.mereni_delky_items{
  position: relative;
  padding: 5px;
}

.swtich_button{
  position: relative;
  text-align: center;
  padding: 10px;
}

#my_components{
  position: relative;
  top: 120px;
  width: 200px;
}

.tlacitka_funkce{
  position: relative;
  padding-left: 10px;
}






body {
  margin: 0;  
  font-family: Garamond, serif;
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/components/mapa/App.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,wBAAwB;EACxB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;;;;;;AAOA;EACE,SAAS;EACT,4BAA4B;EAC5B,eAAe;EACf,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["html,\nbody {\n  margin: 0;\n  height: 100%;\n}\n\nbutton{\n  position: relative;\n  width: 120px;\n  background-color:#f2efe9;\n  border-color: #f2efe9;\n  border-radius: 4px;\n}\n\n#map {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n}\n\n.input_my{\n  position: relative;\n  width: 150px;\n}\n\n#mereni_delky_comp{\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  width: 170px;\n  padding: 10px;\n  justify-content: center;\n}\n\n.mereni_delky_items{\n  position: relative;\n  padding: 5px;\n}\n\n.swtich_button{\n  position: relative;\n  text-align: center;\n  padding: 10px;\n}\n\n#my_components{\n  position: relative;\n  top: 120px;\n  width: 200px;\n}\n\n.tlacitka_funkce{\n  position: relative;\n  padding-left: 10px;\n}\n\n\n\n\n\n\nbody {\n  margin: 0;  \n  font-family: Garamond, serif;\n  font-size: 17px;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
