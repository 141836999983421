import './App.css';

import Visualizer from './components/Visualizer';

function App() {
  return (
    <div className="App_1">
      <Visualizer></Visualizer>
    </div>
  );
}

export default App;
